<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('configurationFundraiseToken.tokenDetails.title') }}
          </h4>
        </div>
        <cp-edit-buttons
          :edit-mode="editMode"
          @changeMode="changeModeProxy"
          @update="confirmChanges"
        >
          <template v-slot:leftToButtons>
            <div>
              <span>{{ $t('configurationFundraiseToken.tokenDetails.tokenStatus') }}: </span>
              <b-badge
                class="cp-badge"
                :variant="tokenStatusVariant"
              >
                {{ $t(`configurationFundraiseToken.tokenDetails.tokenDeploymentStatus.${deploymentToken.status}`) }}
              </b-badge>
            </div>
          </template>
        </cp-edit-buttons>
      </div>
    </div>
    <div
      v-if="errors.length"
      class="card-body"
    >
      <cp-error-notices :errors="errors" />
    </div>
    <div
      v-if="editMode"
      class="card-body"
    >
      <cp-input-container
        ref="cpInputContainer"
        v-model="configurations"
      >
        <div class="row">
          <div class="col-md-6">
            <h5>{{ $t('configurationFundraiseToken.tokenDetails.label.tokenIcon') }}</h5>
            <cp-file-uploader
              id="logo-uploader"
              :default-url="tokenUrl"
              @onChange="onLogoUploaded"
            />
            <table class="table table-striped table-borderless mb-0 mt-3">
              <tbody>
                <tr>
                  <td>{{ $t('configurationFundraiseToken.tokenDetails.label.recommendedSize') }}</td>
                  <td><strong>{{ $t('configurationFundraiseToken.tokenDetails.label.recommendedSizeValue') }}</strong></td>
                </tr>
                <tr>
                  <td>{{ $t('configurationFundraiseToken.tokenDetails.label.recommendedFormat') }}</td>
                  <td><strong>{{ $t('configurationFundraiseToken.tokenDetails.label.recommendedFormatValue') }}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <h5>{{ $t('configurationFundraiseToken.tokenDetails.label.tokenName') }} *</h5>
            <input
              ref="tokenNameInput"
              v-model="configurations.tokenName"
              :class="{'input-error': inputInvalid}"
              type="text"
              class="form-control"
              placeholder=""
              maxlength="255"
              minlength="3"
              required
            >
            <span
              v-if="inputInvalid"
              class="invalid-feedback d-block"
            >
              {{ $t('configurationFundraiseToken.tokenDetails.message.tokenNameValidation') }}
            </span>
            <div class="my-2">
              <span class="input-label d-inline-block mt-3">
                {{ $t('configurationFundraiseToken.tokenDetails.label.decimal') }}
              </span>
              <div class="inline-input">
                <cp-input
                  v-model="configurations.decimals"
                  :validate="'min_value:0|max_value:18'"
                  input-type="number"
                  name="decimals"
                  :disabled="!isTokenDecimalsEditable"
                  input-class="input-override"
                />
              </div>
            </div>
            <div class="my-2">
              <span class="input-label d-inline-block mt-3">
                {{ $t('configurationFundraiseToken.tokenDetails.label.currentTokenValue') }}
              </span>
              <div class="inline-input">
                <cp-input
                  v-model="configurations.defaultTokenValue"
                  disabled="disabled"
                  :validate="validateTokenRepresentsDebt ? 'required|min_value:0': '' "
                  input-type="number"
                  name="defaultTokenValue"
                  :error="customErrors"
                  input-class="input-override"
                />
              </div>
              <span class="d-inline-block mt-3">{{ issuerInfo.mainCurrency }}</span>
            </div>
            <div class="my-2">
              <span class="input-label d-inline-block mt-3">
                {{ $t('configurationFundraiseToken.tokenDetails.label.isin') }}
              </span>
              <div class="inline-input">
                <cp-input
                  v-model="configurations.isin"
                  input-type="text"
                  name="isin"
                  :placeholder="'Ex: US0378331005 or AU0000XVGZA3'"
                  :validate="validateIsin ? { regex: /^[A-Z]{2}[A-Z0-9]{9}[0-9]{1}$/ } : {required: false}"
                  :error="customErrorIsin"
                  input-class="input-override"
                />
              </div>
            </div>

            <div
              v-if="configurations.saft.availableForSaft"
              class="my-2"
            >
              <span class="input-label d-inline-block mt-3">
                {{ $t('configurationFundraiseToken.tokenDetails.label.saft') }}
              </span>
              <div
                class="inline-input cp-select"
              >
                <cp-select
                  v-if="!configurations.saft.isChild && configurations.saft.availableSaft.length > 0"
                  v-model="selectedSaft"
                  style="{width: 300px}"
                  :options="createSaftOptions()"
                  @input="tokenSaftChange"
                />
                <div
                  v-if="configurations.saft.isChild"
                  class="inline-input mt-2 cp-select-msg"
                >
                  {{ $t('configurationFundraiseToken.tokenDetails.saftInputDisplayChild', [configurations.saft.saftInfo.name]) }}
                </div>
                <div
                  v-if="configurations.saft.availableSaft.length === 0"
                  class="inline-input mt-2 cp-select-msg"
                >
                  {{ $t('configurationFundraiseToken.tokenDetails.saftEmptyDdl') }}
                </div>
              </div>
            </div>

            <div class="d-flex flex-wrap">
              <div class="d-flex mr-4 flex-column">
                <div class="mt-3">
                  <b-form-checkbox
                    v-model="isTokenRepresentsDebt"
                    @input="tokenRepresentsDebtCheckboxChange"
                  >
                    <span>{{ $t('configurationFundraiseToken.tokenDetails.label.tokenRepresentsDebt') }}</span>
                  </b-form-checkbox>
                </div>
                <div class="mt-3">
                  <b-form-checkbox
                    v-model="configurations.showTbeWallet"
                  >
                    <span>{{ $t('configurationFundraiseToken.tokenDetails.label.allowDepositWithdraw') }}</span>
                  </b-form-checkbox>
                </div>
                <div class="mt-3">
                  <b-form-checkbox
                    v-model="configurations.isInvestorPays"
                  >
                    <span>{{ $t('configurationFundraiseToken.tokenDetails.label.investorPays') }}</span>
                  </b-form-checkbox>
                </div>
                <div class="mt-3">
                  <b-form-checkbox
                    v-model="configurations.evergreenRound"
                  >
                    <span>{{ $t('configurationFundraiseToken.tokenDetails.label.evergreenRound') }}</span>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="mt-3">
                  <b-form-checkbox
                    v-model="configurations.isDripActive"
                  >
                    <span>{{ $t('configurationFundraiseToken.tokenDetails.label.isDripActive') }}</span>
                  </b-form-checkbox>
                </div>

                <div
                  v-if="configurations.isIssuerScheduledIssuanceActive"
                  class="mt-3"
                >
                  <b-form-checkbox
                    v-model="configurations.isScheduledIssuanceEnabled"
                  >
                    <span> {{ $t('configurationFundraiseToken.tokenDetails.label.isScheduledIssuanceEnabled') }} </span>
                  </b-form-checkbox>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="mt-3">
                  <b-form-checkbox
                    v-model="configurations.isAssetPerformanceActive"
                  >
                    <span>{{ $t('configurationFundraiseToken.tokenDetails.label.isAssetPerformanceActive') }}</span>
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </cp-input-container>
    </div>
    <div
      v-else
      class="card-body"
    >
      <div class="row">
        <div class="col-md-6">
          <h5>{{ $t('configurationFundraiseToken.tokenDetails.label.tokenIcon') }}</h5>
          <div
            class="card border-light mt-3 d-flex justify-content-center align-items-center py-5 w-100 cp-file-preview"
          >
            <img
              v-b-modal.token-icon
              class="d-block w-25"
              :src="tokenUrl"
              alt="Token icon"
            >
          </div>
          <b-modal
            id="token-icon"
            size="lg"
            class="cp-preview-modal"
            title="Token Details - Icon"
            hide-footer
          >
            <span class="modal-logo-wrapper">
              <img
                class="d-inline-block"
                :src="tokenUrl"
                alt="Logo"
              >
            </span>
          </b-modal>
        </div>
        <div class="col-md-6">
          <h5>{{ $t('configurationFundraiseToken.tokenDetails.label.tokenName') }}</h5>
          <div class="card border-light mt-3">
            <div class="card-body">
              {{ configurations.tokenName }}
            </div>
          </div>
          <h5 class="title-spacing">
            {{ $t('configurationFundraiseToken.tokenDetails.label.blockchain') }}
          </h5>
          <div class="card border-light mt-3">
            <div class="card-body">
              {{ issuerInfo.blockchain }}
            </div>
          </div>
          <div class="d-flex">
            <div class="title-to-side">
              {{ $t('configurationFundraiseToken.tokenDetails.label.decimal') }}
            </div>
            <div class="card border-light mt-3 flex-grow-1">
              <div class="card-body">
                {{ configurations.decimals }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="title-to-side">
              {{ $t('configurationFundraiseToken.tokenDetails.label.currentTokenValue') }}
            </div>
            <div class="card border-light mt-3 flex-grow-1">
              <div class="card-body">
                {{ configurations.defaultTokenValue | notAvailable }}
                <span style="float: right">
                  {{ issuerInfo.mainCurrency }}
                </span>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="title-to-side">
              {{ $t('configurationFundraiseToken.tokenDetails.label.isin') }}
            </div>
            <div class="card border-light mt-3 flex-grow-1">
              <div class="card-body">
                {{ configurations.isin || "" }}
              </div>
            </div>
          </div>

          <div
            v-if="configurations.saft.availableForSaft"
            class="d-flex"
          >
            <div class="title-to-side">
              {{ $t('configurationFundraiseToken.tokenDetails.label.saft') }}
            </div>
            <div class="card border-light mt-3 flex-grow-1">
              <div class="card-body">
                {{ getSaftTokenForDisplay() }}
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap">
            <div class="d-flex mr-4 flex-column">
              <div class="d-flex">
                <div class="title-to-side">
                  {{ $t('configurationFundraiseToken.tokenDetails.label.tokenRepresentsDebt') }}
                </div>
                <div class="mt-2 flex-grow-1">
                  <span
                    :class="[isTokenRepresentsDebt ? 'ion-md-checkmark text-success': 'text-danger ion-md-close ']"
                    class="ion  configuration-setting"
                  />
                </div>
              </div>
              <div class="d-flex">
                <div class="title-to-side">
                  {{ $t('configurationFundraiseToken.tokenDetails.label.allowDepositWithdraw') }}
                </div>
                <div class="mt-1 flex-grow-1">
                  <span
                    :class="[configurations.showTbeWallet ? 'ion-md-checkmark text-success': 'text-danger ion-md-close ']"
                    class="ion  configuration-setting"
                  />
                </div>
              </div>
              <div class="d-flex">
                <div class="title-to-side">
                  {{ $t('configurationFundraiseToken.tokenDetails.label.investorPays') }}
                </div>
                <div class="mt-1 flex-grow-1">
                  <span
                    :class="[configurations.isInvestorPays ? 'ion-md-checkmark text-success': 'text-danger ion-md-close ']"
                    class="ion  configuration-setting"
                  />
                </div>
              </div>
              <div class="d-flex">
                <div class="title-to-side">
                  {{ $t('configurationFundraiseToken.tokenDetails.label.evergreenRound') }}
                </div>
                <div class="mt-1 flex-grow-1">
                  <span
                    :class="[configurations.evergreenRound ? 'ion-md-checkmark text-success': 'text-danger ion-md-close ']"
                    class="ion  configuration-setting"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <div class="title-to-side">
                  {{ $t('configurationFundraiseToken.tokenDetails.label.isDripActive') }}
                </div>
                <div class="mt-2 flex-grow-1">
                  <span
                    :class="[configurations.isDripActive ? 'ion-md-checkmark text-success': 'text-danger ion-md-close ']"
                    class="ion  configuration-setting"
                  />
                </div>
              </div>
              <div
                v-if="configurations.isIssuerScheduledIssuanceActive"
                class="d-flex"
              >
                <div class="title-to-side">
                  {{ $t('configurationFundraiseToken.tokenDetails.label.isScheduledIssuanceEnabled') }}
                </div>
                <div class="mt-2 flex-grow-1">
                  <span
                    :class="[configurations.isScheduledIssuanceEnabled ? 'ion-md-checkmark text-success': 'text-danger ion-md-close ']"
                    class="ion  configuration-setting"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex">
                <div class="title-to-side">
                  {{ $t('configurationFundraiseToken.tokenDetails.label.isAssetPerformanceActive') }}
                </div>
                <div class="mt-2 flex-grow-1">
                  <span
                    :class="[configurations.isAssetPerformanceActive ? 'ion-md-checkmark text-success': 'text-danger ion-md-close ']"
                    class="ion  configuration-setting"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-0 mx-0">
    <cp-historical-token-values
      :main-currency="issuerInfo.mainCurrency"
      @onChange="handleTokenValueChanges"
    />
    <cp-documents-table />
    <cp-confirm-modal
      ref="cpConfirm"
      :title="$t('configurationFundraiseToken.tokenDetails.confirmModal.title')"
      @onOk="save"
    >
      <strong>{{ $t('configurationFundraiseToken.tokenDetails.confirmModal.attention') }}</strong>
      {{ $t('configurationFundraiseToken.tokenDetails.confirmModal.message', [tokenConfiguration.decimals, configurations.decimals]) }}
    </cp-confirm-modal>

    <cp-confirm-modal
      ref="cpConfirmActiveEvergreenRound"
      :title="$t('configurationFundraiseToken.tokenDetails.confirmModalEverGreen.title')"
      :ok-text="$t('configurationFundraiseToken.tokenDetails.confirmModalEverGreen.okBtnText')"
      ok-only
      @onOk="goToFundraise"
    >
      <strong>{{ $t('configurationFundraiseToken.tokenDetails.confirmModalEverGreen.attention') }}</strong>
      {{ $t('configurationFundraiseToken.tokenDetails.confirmModalEverGreen.message', ) }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import CpEditButtons from '~/components/common/edit-buttons';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import CpEditableMixin from '~/mixins/editable-component';
import CpDocumentsTable from './documents';
import CpInputContainer from '~/components/common/cpInputContainer';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import { CpInput } from '~/components/common/standalone-components/inputs';
import routes from '../../../../../utilities/routes';
import CpHistoricalTokenValues
  from '@/pages/_idIssuer/configuration/fundraise-token/components/historical-token-value.vue';
import CpSelect from '@/components/common/standalone-components/inputs/cp-select.vue';
import { i18n } from '@/i18n';

export default {
  name: 'CpTokenDetails',
  components: {
    CpSelect,
    CpHistoricalTokenValues,
    CpFileUploader,
    CpEditButtons,
    CpDocumentsTable,
    CpInput,
    CpInputContainer,
    CpConfirmModal,
  },

  mixins: [
    CpEditableMixin,
    staticPathGenerationMixin,
  ],

  props: {
    tokenConfiguration: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      errors: [],
      inputInvalid: false,
      configurations: JSON.parse(JSON.stringify(this.tokenConfiguration || { documents: [] })),
      customPrimaryColor: '',
      customErrors: '',
      customErrorIsin: '',
      validateTokenRepresentsDebt: true,
      validateIsin: true,
      isTokenRepresentsDebt: this.tokenConfiguration.isTokenRepresentsDebt,
      colorFields: ['primary', 'secondary', 'action'],
      newName: '',
      tokenName: '',
      walletRegisterPaymentStrategy: {
        issuer: 'issuer',
        investor: 'investor',
      },
      selectedSaft: undefined,
    };
  },

  computed: {
    ...mapState('configuration', ['isLoading']),
    ...mapGetters('configuration', ['getGeneral']),
    ...mapGetters('issuersInfo', ['issuerInfo', 'issuerTokens']),
    ...mapState('configToken', ['deploymentToken']),
    ...mapGetters('currentOperator', ['authorizationLevel']),
    tokenUrl() {
      const url = this.configurations.tokenIconUrl || this.getImagePath('upload.png', 'theme');
      return `${url}?random=${new Date().getTime()}`;
    },
    tokenStatusVariant() {
      const statusMap = {
        initial: 'secondary',
        pending: 'warning',
        inProgress: 'warning',
        success: 'success',
        failure: 'danger',
      };
      const { status } = this.deploymentToken;
      return statusMap[status] || 'warning';
    },
    isTokenDecimalsEditable() {
      return this.authorizationLevel === 'megaadmin' && !this.tokenConfiguration.isDeployed;
    },
  },

  watch: {
    tokenConfiguration: {
      handler(newValue) {
        this.configurations = JSON.parse(JSON.stringify(newValue || {}));
        this.selectedSaft = this.getTokenSaft();
        this.tokenSaftChange(this.selectedSaft);
      },
      deep: true,
    },
    'configurations.isin': {
      handler(newValue) {
        if (newValue) {
          this.validateIsin = true;
          this.validateSecurityInternationalId(newValue);
        } else {
          this.validateIsin = false;
          this.customErrorIsin = '';
        }
      },
      deep: true,
    },
  },
  created() {
    const { tokenId, idIssuer: issuerId } = this.$route.params;
    this.updateTokenStatus(tokenId, issuerId);
    this.selectedSaft = this.getTokenSaft();
    this.tokenSaftChange(this.selectedSaft);
  },
  methods: {
    ...mapActions('configToken', ['getTokenDeployment', 'updateTokenConfiguration']),
    ...mapActions('configuration', ['updateTokenConfiguration']),
    ...mapMutations('issuersInfo', ['SET_CURRENT_TOKEN']),
    onLogoUploaded({ fileKey }) {
      this.configurations.tokenIconKey = fileKey;
    },
    tokenRepresentsDebtCheckboxChange(val) {
      this.validateTokenRepresentsDebt = val;
      if (!val.length) {
        this.customErrors = '';
      }
    },
    getTokenSaft() {
      return this.configurations.saft.isParent ? this.configurations.saft.saftInfo.id : undefined;
    },
    tokenSaftChange(val) {
      this.selectedSaft = val;
    },
    setConfigState() {
      this.configurations = JSON.parse(JSON.stringify(this.tokenConfiguration || {}));
    },
    createSaftOptions() {
      if (this.selectedSaft === undefined) this.tokenSaftChange(this.getTokenSaft());
      const noneOption = { value: null, text: i18n.t('common.label.none') };
      const res = this.configurations.saft.availableSaft.map(item => ({ value: item.id, text: item.name }));
      return [noneOption, ...res];
    },

    getSaftTokenForDisplay() {
      if (this.configurations.saft.availableSaft && this.configurations.saft.availableSaft.length === 0) return i18n.t('configurationFundraiseToken.tokenDetails.saftEmptyDdl');
      if (this.configurations.saft.isChild) return i18n.t('configurationFundraiseToken.tokenDetails.saftInputDisplayChild', [this.configurations.saft.saftInfo.name]);
      if (this.configurations.saft.isParent) return this.configurations.saft.saftInfo.name;
      return '';
    },

    clearTouched() {
      delete this.configurations.tokenIconKey;
      this.inputInvalid = false;
      this.setConfigState();
      this.errors = [];
    },
    changeModeProxy() {
      if (!this.configurations.defaultTokenValue) {
        this.isTokenRepresentsDebt = false;
      }
      if (this.editMode) {
        // on cancel clear the image IDs to prevent accidental upload
        this.clearTouched();
        this.customErrors = '';
      } else {
        this.$emit('onEditActivated');
        this.setConfigState();
      }
      this.changeMode();
    },
    validateSecurityInternationalId(secInternationalId) {
      // eslint-disable-next-line no-unused-expressions
      (!this.validateSecurityInternationalIdRegex(secInternationalId))
        ? this.customErrorIsin = this.$t('configurationFundraiseToken.tokenDetails.error.isin')
        : this.customErrorIsin = '';
    },
    validateSecurityInternationalIdRegex(secInternationalId) {
      const regex = /^[A-Z]{2}[A-Z0-9]{9}[0-9]{1}$/;
      return regex.test(secInternationalId);
    },
    confirmChanges() {
      this.$refs.cpInputContainer.validateForm().then((isValid) => {
        if (isValid) {
          if (this.configurations.decimals !== this.tokenConfiguration.decimals) {
            this.$refs.cpConfirm.show();
          } else {
            this.save();
          }
        }
      });
    },
    save() {
      const { idIssuer: issuerId } = this.$route.params;
      this.errors = [];
      if (!this.$refs.tokenNameInput.checkValidity()) {
        this.inputInvalid = true;
        return;
      }
      const {
 tokenName, tokenIconKey, defaultTokenValue, decimals, showTbeWallet, isInvestorPays, evergreenRound,
        isDripActive, isin, isAssetPerformanceActive, isIssuerScheduledIssuanceActive, isScheduledIssuanceEnabled,
} = this.configurations;
      const body = {
        tokenName,
        tokenIconKey,
        defaultTokenValue,
        isTokenRepresentsDebt: this.isTokenRepresentsDebt,
        showTbeWallet,
        isInvestorPays,
        decimals,
        evergreenRound,
        isDripActive,
        isin,
        isAssetPerformanceActive,
        saft: this.selectedSaft,
        ...isIssuerScheduledIssuanceActive ? { isScheduledIssuanceEnabled } : null,
      };
      const tokenEvergreenStatusFromBeforeSave = this.tokenConfiguration.evergreenRound;
      if (this.configurations.isin && !this.validateSecurityInternationalIdRegex(this.configurations.isin)) {
        this.customErrorIsin = this.$t('configurationFundraiseToken.tokenDetails.error.isin');
      }
      if (this.isTokenRepresentsDebt && defaultTokenValue <= 0) {
        this.customErrors = this.$t('configurationFundraiseToken.tokenDetails.error.tokenRepresentsDebt');
      }

      if (!this.customErrorIsin && !this.customErrors) {
        this.customErrors = '';
        this.customErrorIsin = '';
        const { tokenId } = this.$route.params;
        this.updateTokenConfiguration({ issuerId, tokenId, body })
            .then(
                () => {
                  this.changeMode();
                  const token = this.issuerTokens.find(item => item.id === tokenId);
                  this.SET_CURRENT_TOKEN(token);
                  if (this.configurations.evergreenRound !== tokenEvergreenStatusFromBeforeSave && this.configurations.evergreenRound) {
                    this.$refs.cpConfirmActiveEvergreenRound.show();
                  }
                },
            );
      }
    },
    updateTokenStatus(tokenId, issuerId) {
      this.getTokenDeployment({ tokenId, issuerId }).then();
    },
    goToFundraise() {
      const { tokenId, idIssuer: issuerId } = this.$route.params;
      this.$router.push(routes.fundraiseConfiguration(issuerId, tokenId));
    },
    handleTokenValueChanges() {
      this.$emit('onTokenValueChange');
    },
  },
};
</script>

<style src="~/vendor/libs/minicolors/minicolors.css"></style>
<style lang="scss" scoped>
  .title-to-side {
    align-self: center;
    padding-top: 4px;
    margin-top: 1rem;
    margin-right: 4px;
    min-width: 205px;
  }
  .configuration-setting {
    padding: 1rem .5rem 0.5rem .5rem;
    font-size: 20px;
  };
.cp-badge {
  text-transform: capitalize;
  margin: 2px 10px;
  line-height: inherit;
  height: 22px;
  padding-left: 12px;
  padding-right: 12px;
}

.input-override {
  margin: 0;
}

.input-error {
  border: 1px solid red;
}

.input-label {
  width: 200px;
}

.inline-input {
  margin: 4px;
  display: inline-block;
  min-width: 20px;
  max-width: 200px;
  vertical-align: top;
}

.cp-select {
  width: 177px;
}

  .cp-select-msg {
    padding-top: 3px;
    min-width: 325px;
    font-weight: 900
  }

.title-spacing {
  margin-top: 1rem;
}

.cp-preview-modal {
  text-align: center;
}

.modal-logo-wrapper {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.cp-file-preview {
  min-height: 256px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    cursor: pointer;
    margin: auto;
  }
}
</style>
