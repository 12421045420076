var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t( "configurationFundraiseToken.tokenDetails.historicalTokenValues.title" ))+" ")])])])]),_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"configToken/getHistoricalTokenValues","url-params":_vm.urlParams,"fields":_vm.historicalTokenValueFields},scopedSlots:_vm._u([{key:"tokenValueDate",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.tokenValueDate))+" ")]}},{key:"tokenValue",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.tokenValue)+" ")]}},{key:"modificationDate",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.modificationDate))+" ")]}},{key:"addedBy",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.addedBy)+" ")]}},{key:"id",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticStyle:{"float":"right"}},[_vm._v(" "),_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"variant":"default action-button btn-xs md-btn-flat","title":_vm.$t('common.button.edit')},on:{"click":function($event){return _vm.toEditModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create"})]),_c('span',{staticClass:"ion ion-md-trash",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openDeleteModal(rowData.item)}}})],1)]}}])}),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":_vm.toCreateModal}},[_c('i',{staticClass:"ion ion-ios-add-circle-outline"}),_vm._v(" "+_vm._s(_vm.$t( "configurationFundraiseToken.tokenDetails.historicalTokenValues.actions.addTokenValue" ))+" ")])],1)])]),_c('cp-delete-modal',{ref:"cpDeleteAlert",attrs:{"item-info":_vm.deleteItem},on:{"delete-item":_vm.removeItem}}),_c('cp-historical-token-value-modal',{ref:"historicalTokenValueModal",attrs:{"main-currency":_vm.mainCurrency,"title-data":_vm.titleData,"modal-data":_vm.modalData,"mode":_vm.modalMode},on:{"onSubmit":_vm.saveChanges}}),_c('cp-confirm-modal',{ref:"cpConfirmTokenValueOverwrite",attrs:{"title":_vm.$t(
        'configurationFundraiseToken.tokenDetails.historicalTokenValues.modal.overwrite.title'
      ),"ok-text":_vm.$t(
        'configurationFundraiseToken.tokenDetails.historicalTokenValues.modal.overwrite.confirm'
      )},on:{"onOk":_vm.overwrite}},[_vm._v(" "+_vm._s(_vm.$t( "configurationFundraiseToken.tokenDetails.historicalTokenValues.modal.overwrite.message", [ _vm.formatTokenValueDate(_vm.overwriteData.tokenValueDate), _vm.overwriteData.previousTokenValue, _vm.overwriteData.tokenValue ] ))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }