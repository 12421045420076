<template>
  <div>
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h5 class="mb-0">
            {{ $t('configurationFundraiseToken.tokenDetails.label.documents') }}
          </h5>
        </div>
      </div>
    </div>

    <cp-table
      ref="cpTable"
      get-data-action="configToken/getDocuments"
      :url-params="urlParams"
      :fields="documentsFields"
      :data-parser="listCorrection"
    >
      <template
        slot="category"
        slot-scope="{ rowData }"
      >
        {{ $t(rowData.item.categoryI18nKey) }}
      </template>
      <template
        slot="image"
        slot-scope="{ rowData }"
      >
        <img
          class="ui-w-40 d-block"
          :src="rowData.item.image || getImagePath('fallback-image.jpg')"
          alt="Document image"
        >
      </template>
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.createdAt | dateFilter }}
      </template>
      <template
        slot="url"
        slot-scope="{ rowData }"
      >
        <span style="float: right">
          <a
            target="_blank"
            :href="rowData.item.url"
          >
            <b-button
              :disabled="downloadingDocuments[rowData.item.id]"
              variant="outline-primary"
              size="sm"
              style="position:relative"
            >
              <Spinner
                v-if="downloadingDocuments[rowData.item.id]"
                name="wave"
                color="#04bec4"
                class="cp-button-spinner"
                fade-in="quarter"
              />
              <i class="ion ion-ios-arrow-round-down" />
              &nbsp;&nbsp;{{ $t('configurationFundraiseToken.tokenDetails.button.document.download') }}
            </b-button>
          </a>
          &nbsp;
          <b-btn
            v-b-tooltip.hover="true"
            variant="default action-button btn-xs md-btn-flat"
            :title="$t('common.button.edit')"
            @click="toEditModal(rowData.item)"
          >
            <i class="ion ion-md-create" />
          </b-btn>
          <span
            class="ion ion-md-trash"
            style="cursor: pointer"
            @click="openDeleteModal(rowData.item)"
          />
        </span>
      </template>
    </cp-table>

    <div class="card-footer">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="toCreateModal"
          >
            <i class="ion ion-ios-add-circle-outline" />
            &nbsp;&nbsp; {{ $t('configurationFundraiseToken.tokenDetails.button.document.add') }}
          </b-button>
        </div>
      </div>
    </div>

    <cp-delete-modal
      ref="cpDeleteAlert"
      :item-info="deleteItem"
      @delete-item="removeItem"
    />

    <cp-document-modal
      ref="issuerDocumentModal"
      :title-data="titleData"
      :modal-data="modalData"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Spinner from 'vue-spinkit';
import CpEditableMixin from '~/mixins/editable-invester-details-component';
import staticPathGeneration from '~/mixins/static-path-generation';

import CpTable from '~/components/shared/cp-table';
import CpDeleteModal from '~/components/common/table/deleteModal';
import CpDocumentModal from '~/pages/_idIssuer/advanced/issuer-platform-documents/issuer-document-modal';

export default {
  name: 'CpTokenDetailsDocuments',

  components: {
    Spinner,
    CpTable,
    CpDeleteModal,
    CpDocumentModal,
  },

  mixins: [
    CpEditableMixin('documents'),
    staticPathGeneration,
  ],

  data() {
    return {
      downloadingDocuments: {},
      documentsFields: [
        { key: 'type', i18nKey: 'configurationFundraiseToken.tokenDetails.label.table.type' },
        { key: 'category', i18nKey: 'configurationFundraiseToken.tokenDetails.label.table.category', tdClass: 'align-middle' },
        { key: 'name', i18nKey: 'configurationFundraiseToken.tokenDetails.label.table.name', tdClass: 'align-middle' },
        { key: 'title', i18nKey: 'configurationFundraiseToken.tokenDetails.label.table.title', tdClass: 'align-middle' },
        { key: 'url', label: ' ', tdClass: 'text-nowrap align-middle text-center' },
      ],
      categories: {
        basic: 'configurationFundraiseToken.tokenDetails.status.category.basic',
        'investor-only': 'configurationFundraiseToken.tokenDetails.status.category.investorOnly',
      },
      modalData: {},
      titleData: {},
      deleteItem: null,
      urlParams: { issuerId: this.$route.params.idIssuer, tokenId: this.$route.params.tokenId },
    };
  },
  watch: {
    $route({ params: { tokenId: newTokenId } }, { params: { tokenId: oldTokenId } }) {
      if (newTokenId !== oldTokenId) this.urlParams.tokenId = newTokenId;
    },
  },
  methods: {
    ...mapActions({
      create: 'configToken/createTokenDocument',
      getById: 'configToken/getTokenDocument',
      update: 'configToken/updateTokenDocument',
      delete: 'configToken/deleteTokenDocument',
    }),

    listCorrection(data) {
      return data.map(item => ({
        ...item,
        categoryI18nKey: this.categories[item.category],
      }));
    },

    hideModal() {
      this.$refs.issuerDocumentModal.$refs.issuerDoc.hide();
    },

    showModal() {
      this.$refs.issuerDocumentModal.$refs.issuerDoc.show();
    },

    openDeleteModal(item) {
      this.deleteItem = item;
      this.$refs.cpDeleteAlert.$refs.deleteAlert.open();
    },

    toCreateModal() {
      this.titleData = {
        type: this.$t('common.button.add'),
        icon: 'ios-add-circle-outline',
      };

      this.modalData = { type: 'pdf', category: 'basic' };
      this.showModal();
    },

    toEditModal({ id }) {
      this.titleData = {
        type: this.$t('common.button.edit'),
        icon: 'md-create',
      };
      const { issuerId, tokenId } = this.urlParams;
      this.getById({ issuerId, tokenId, id }).then((data) => {
        this.modalData = data;
        this.showModal();
      });
    },
    removeItem() {
      const { id } = this.deleteItem;
      const { issuerId, tokenId } = this.urlParams;
      this.delete({ issuerId, tokenId, id }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.cpDeleteAlert.$refs.deleteAlert.close();
        setTimeout(() => {
          this.deleteItem = null;
        }, 1000);
      });
    },
    saveChanges({ action, data }) {
      const { issuerId, tokenId } = this.urlParams;
      this[action]({ issuerId, tokenId, data }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.hideModal();
      });
    },
  },
};
</script>

<style lang="scss">
  #about-background-dropzone{
    cursor: pointer;
    .dz-progress{
      display: none;
    }
    .dz-preview{
      pointer-events: none;
    }
  }
</style>
<style lang="scss" scoped>
  .cp-button-spinner{
    width: 100%;
    height: 20px;
    position: absolute;
    margin: auto;
    background: rgba(255,255,255,1);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.6;
  }
  .action-button {
    border: 0 solid transparent;
    &:hover {
      background: transparent;
    }
    i {
      font-size: 0.894rem;
    }
  }
  .card-header {
    border-bottom: none;
  }
</style>
