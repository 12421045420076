<template>
  <cp-general-modal
    ref="historicalTokenValue"
    :title="getTitleName"
    :title-icon="`ion ion-${titleData.icon}`"
    :form-group-value="modalData"
    @onOk="handleSubmit"
  >
    <div class="row">
      <div class="col-md-12">
        <cp-date-picker
          v-model="modalData.tokenValueDate"
          name="tokenValueDate"
          :label="$t('configurationFundraiseToken.tokenDetails.historicalTokenValues.modal.fields.tokenValueDate')"
          validate="required"
          :disabled="mode!=='create'"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <cp-input
          v-model="modalData.tokenValue"
          name="tokenValue"
          :label="$t('configurationFundraiseToken.tokenDetails.historicalTokenValues.modal.fields.tokenValue')"
          :validate="'required|min_value:0|excluded:0'"
          :append="mainCurrency"
        />
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpDatePicker from '@/components/common/standalone-components/inputs/cp-date-picker.vue';

export default {
  name: 'CpHistoricalTokenValueModal',
  components: {
    CpDatePicker,
    CpGeneralModal,
    CpInput,
  },

  props: {
    titleData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: [String],
      default: () => 'create',
    },
    modalData: {
      type: Object,
      default: () => {},
    },
    mainCurrency: {
      type: String,
      default: 'USD',
    },
  },

  data() {
    return {
      model: {},
    };
  },

  computed: {
    getTitleName() {
      return this.mode === 'create'
       ? this.$t('configurationFundraiseToken.tokenDetails.historicalTokenValues.modal.create.title')
          : this.$t('configurationFundraiseToken.tokenDetails.historicalTokenValues.modal.edit.title');
    },
  },

  watch: {
    modalData: {
      handler(val) {
        this.model = Object.assign({}, val);
      },
    },
  },

  methods: {
    handleSubmit(formData) {
      this.$emit('onSubmit', { ...formData, id: this.model.id });
    },
  },
};
</script>
