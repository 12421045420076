var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('configurationFundraiseToken.tokenDetails.label.documents'))+" ")])])])]),_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"configToken/getDocuments","url-params":_vm.urlParams,"fields":_vm.documentsFields,"data-parser":_vm.listCorrection},scopedSlots:_vm._u([{key:"category",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.$t(rowData.item.categoryI18nKey))+" ")]}},{key:"image",fn:function(ref){
var rowData = ref.rowData;
return [_c('img',{staticClass:"ui-w-40 d-block",attrs:{"src":rowData.item.image || _vm.getImagePath('fallback-image.jpg'),"alt":"Document image"}})]}},{key:"createdAt",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.createdAt))+" ")]}},{key:"url",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticStyle:{"float":"right"}},[_c('a',{attrs:{"target":"_blank","href":rowData.item.url}},[_c('b-button',{staticStyle:{"position":"relative"},attrs:{"disabled":_vm.downloadingDocuments[rowData.item.id],"variant":"outline-primary","size":"sm"}},[(_vm.downloadingDocuments[rowData.item.id])?_c('Spinner',{staticClass:"cp-button-spinner",attrs:{"name":"wave","color":"#04bec4","fade-in":"quarter"}}):_vm._e(),_c('i',{staticClass:"ion ion-ios-arrow-round-down"}),_vm._v(" "+_vm._s(_vm.$t('configurationFundraiseToken.tokenDetails.button.document.download'))+" ")],1)],1),_vm._v(" "),_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"variant":"default action-button btn-xs md-btn-flat","title":_vm.$t('common.button.edit')},on:{"click":function($event){return _vm.toEditModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create"})]),_c('span',{staticClass:"ion ion-md-trash",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openDeleteModal(rowData.item)}}})],1)]}}])}),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":_vm.toCreateModal}},[_c('i',{staticClass:"ion ion-ios-add-circle-outline"}),_vm._v(" "+_vm._s(_vm.$t('configurationFundraiseToken.tokenDetails.button.document.add'))+" ")])],1)])]),_c('cp-delete-modal',{ref:"cpDeleteAlert",attrs:{"item-info":_vm.deleteItem},on:{"delete-item":_vm.removeItem}}),_c('cp-document-modal',{ref:"issuerDocumentModal",attrs:{"title-data":_vm.titleData,"modal-data":_vm.modalData},on:{"onSubmit":_vm.saveChanges}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }